import {
  BLOCK_COLOR_CODES,
  BlockCategory,
  BlockType,
} from 'App/Lib/Blockly/types';
import { BlockDefinition } from 'blockly/core/blocks';
import { PIN_NUMBER_OPTIONS } from '../pin';

const definition = (blockly: BlockDefinition) => {
  blockly.Blocks[BlockType.ANALOG_WRITE] = {
    init: function () {
      this.appendDummyInput()
        .appendField('set pin')
        .appendField(new blockly.FieldDropdown(PIN_NUMBER_OPTIONS), 'PIN_VALUE')
        .appendField('to')
        .appendField(new blockly.FieldNumber(0, -Infinity, 255), 'PIN_STATE');
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(BLOCK_COLOR_CODES[BlockCategory.IO]);
      this.setTooltip('Set pin value');
    },
  };
};

export default definition;
